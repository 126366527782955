/* eslint-disable */
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const FarmRegistration = lazy(() => import('../../container/farm/farmRegistration'));
const FarmList = lazy(() => import('../../container/farm/viewFarm'));
const FarmUpdate = lazy(() => import('../../container/farm/farmUpdate'));
const FarmDetails = lazy(() => import('../../container/farm/viewFarm/detailFarm'));

function FarmRoute() {
  return (
    <Routes>
      <Route path="registration" element={<FarmRegistration />} />
      <Route path="list" element={<FarmList />} />
      <Route path="edit/:farmId" element={<FarmUpdate />} />
      <Route path="view/:farmId" element={<FarmDetails />} />
      {/* <Route path="details/:farmerId" element={<FarmView />} /> */}
    </Routes>
  );
}

export default FarmRoute;
