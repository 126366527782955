import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const SoilTestResult = lazy(() => import('../../container/soil/soilTestResult'));
const SoilTestList = lazy(() => import('../../container/soil/soilTestResultList'));
const SoilTestUpdate = lazy(() => import('../../container/soil/soilTestResultUpdate'));
// const FarmDetails = lazy(() => import('../../container/farm/FarmDetail/farmDetails'));
const SoilTestResultView = lazy(() => import('../../container/soil/soilTestResultDetail/soilTestResultView'));
const SoilTestAgent = lazy(() => import('../../container/soil/agent/soilTestAgent'));

function SoilRoute() {
  return (
    <Routes>
      <Route path="test-result" element={<SoilTestResult />} />
      <Route path="test-result-list" element={<SoilTestList />} />
      <Route path="test-result-update/:_id" element={<SoilTestUpdate />} />
      {/* <Route path="details/:farmId" element={<FarmDetails />} /> */}
      <Route path="results-view/:_id" element={<SoilTestResultView />} />
      <Route path="soil-agent-test" element={<SoilTestAgent />} />
    </Routes>
  );
}

export default SoilRoute;
