/* eslint-disable */
import React from 'react';
import { Routes, Route } from 'react-router-dom';

const ViewAnalytics = React.lazy(() => import('../../container/analytics/viewAnalytics'));

function AnalyticsRoute() {
  return (
    <Routes>
      <Route path="all" element={<ViewAnalytics />} />
    </Routes>
  );
}

export default AnalyticsRoute;
