/* eslint-disable */
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const FarmhubRegistration = lazy(() => import('../../container/farmhub/addFarmhub'));
const FarmhubList = lazy(() => import('../../container/farmhub/viewFarmhub'));
const FarmhubEdit = lazy(() => import('../../container/farmhub/editFarmhub'));
const FarmhubDetails = lazy(() => import('../../container/farmhub/viewFarmhub/detailFarmhub'));

function FarmRoute() {
  return (
    <Routes>
      <Route path="add" element={<FarmhubRegistration />} />
      <Route path="list" element={<FarmhubList />} />
      <Route path="edit/:farmhubId" element={<FarmhubEdit />} />
      <Route path="view/:farmhubId" element={<FarmhubDetails />} />
    </Routes>
  );
}

export default FarmRoute;
