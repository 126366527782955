import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
// import UilBell from '@iconscout/react-unicons/icons/uil-bell';
// import UilDollarSign from '@iconscout/react-unicons/icons/uil-dollar-sign';
// import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
// import UilUser from '@iconscout/react-unicons/icons/uil-user';
// import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { InfoWraper, UserDropDwon } from './auth-info-style';
// import Message from './Message';
// import Notification from './Notification';
// import Search from './Search';
// import Settings from './settings';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
// import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import { DataService } from '../../../config/dataService/dataService';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const username = Cookies.get('username') || '';
  const userNationalId = Cookies.get('userNationalId') || '';
  const [userRole, setUserRole] = useState('');

  // get the Cookie value of userNationalId

  useEffect(() => {
    const fetchUser = async () => {
      const response = await DataService.get('/users', userNationalId);
      const { data } = response;
      // console.log(data);
      // console.log(data[0].role);
      setUserRole(data[0].role);
    };
    fetchUser();
  }, []);

  const navigate = useNavigate();

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate('/')));
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">{username}</Heading>
            <p>{userRole}</p>
          </figcaption>
        </figure>
        {/* <ul className="user-dropdwon__links">
          <li>
            <Link to="#">
              <UilUser /> Profile
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilSetting /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilDollarSign /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilUsersAlt /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilBell /> Help
            </Link>
          </li>
        </ul> */}
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      {/* <Search />
      <Message />
      <Notification />
      <Settings /> */}
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
            <span className="ninjadash-nav-actions__author--name">{username}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
