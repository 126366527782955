import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const HomePage = lazy(() => import('../../container/pages/HomePage'));

function PagesRoute() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="starter" element={<HomePage />} />
    </Routes>
  );
}

export default PagesRoute;
