import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const FarmHistoryUpdate = lazy(() => import('../../container/farmHistory/farmHistoryUpdate'));
const FarmHistoryView = lazy(() => import('../../container/farmHistory/farmHistoryDetail/farmHistoryView'));

function FarmHistoryRoute() {
  return (
    <Routes>
      <Route path="update-history/:_id" element={<FarmHistoryUpdate />} />
      <Route path="view-history/:_id" element={<FarmHistoryView />} />
    </Routes>
  );
}

export default FarmHistoryRoute;
