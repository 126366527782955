import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const BlogCreation = lazy(() => import('../../container/blog/blogCreation'));
const BlogList = lazy(() => import('../../container/blog/blogList'));
const BlogUpdate = lazy(() => import('../../container/blog/blogUpdate'));
function BlogRoute() {
  return (
    <Routes>
      <Route path="creation" element={<BlogCreation />} />
      <Route path="list" element={<BlogList />} />
      <Route path="update/:slug" element={<BlogUpdate />} />
    </Routes>
  );
}

export default BlogRoute;
