/* eslint-disable */
import React from 'react';
import { Routes, Route } from 'react-router-dom';

const FarmersFinanceList = React.lazy(() => import('../../container/finance/viewFinance'));
const FarmerWalletHome = React.lazy(() => import('../../container/finance/view/FarmerWallet'));

function FinancesRoute() {
  return (
    <Routes>
      <Route path="list" element={<FarmersFinanceList />} />
      <Route path="view/:NationalId" element={<FarmerWalletHome />} />
    </Routes>
  );
}

export default FinancesRoute;
